import { useSearchRadius } from "@/pages/(app)/marketplace/useSearchRadius";
import { queryOptions, useQuery } from "@tanstack/react-query";
import { api, queryClient } from "./utils";

const makeHomeQueryOptions = (searchRadius: number, includeDrafts = false) =>
  queryOptions({
    queryKey: ["marketplace-home", { searchRadius, includeDrafts }],
    queryFn: async () => {
      const results = await api.home.getMarketplaceHome({ searchRadiusMeters: searchRadius, includeDrafts });
      return results.groups?.filter(g => g.listings?.length) || [];
    },
  });

export function useMarketplaceHomeQuery(includeDrafts = false) {
  const { searchRadius } = useSearchRadius();
  return useQuery(makeHomeQueryOptions(searchRadius, includeDrafts));
}
useMarketplaceHomeQuery.prefetch = () => queryClient.prefetchQuery(makeHomeQueryOptions(40000));
